<template>
  <div class="wap-setting">
    <headerBar :title="$t('she-zhi-1')" @back="back"></headerBar>

    <div class="setting-line flex-center-between">
      <div class="label">{{ $t('qing-chu-huan-cun') }}</div>
      <div class="value">0M</div>
    </div>
    <div class="setting-line flex-center-between">
      <div class="label">{{ $t('jian-cha-geng-xin') }}</div>
      <div class="value">
        V1.0.1
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <Button class="logout-btn" @click="logout" type="danger">{{
      $t('tui-chu')
    }}</Button>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import { removeToken } from '@/utils/auth'
export default {
  components: {
    Button,
    headerBar
  },
  data() {
    return {
      avatar: ''
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    header() {
      return this.$store.state.avatar
    }
  },
  mounted() {
    this.avatar = this.header
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    logout() {
      removeToken()
      this.$store.state.isLogin = false
      this.$router.push({
        name: 'wapHome'
      })
    }
  }
}
</script>